<template>
    <v-container>
        <div style="background-color:white;padding:20px;margin-bottom:20px;">
             <v-container class="">
                <v-row>

                  <v-col>
                        <v-select class="input-hour" :items="listadesistemas" label="Sistema"
                            name="sistema" item-text="nome" item-value="id" v-model="sistema" outlined dense hide-details  return-object
                            @change="selecionarTipoRelatorio()">
                        </v-select>
                  </v-col>

                  <v-col>
                        <v-select class="input-hour" :items="listadecliente" label="Cliente"
                            name="sistema" item-text="nome" item-value="id" v-model="cliente" outlined dense hide-details return-object >
                        </v-select>
                  </v-col>

                  <v-col>
                        <!-- <v-select class="input-hour" :items="listademodos" label="Modo"
                            name="sistema" item-text="nome" item-value="id" v-model="modo" outlined
                            dense hide-details >
                        </v-select> -->
                        <v-text-field type="date" label="Dia Inicial"  v-model="diainicial" outlined dense hide-details >
                        </v-text-field>
                        
                  </v-col>

                  <v-col>
                        <v-text-field type="date" label="Dia Inicial"  v-model="diafinal" outlined dense hide-details >
                        </v-text-field>
                  </v-col>

                </v-row>

                <v-row>

                  <v-col>
                        <v-select class="input-hour" :items="listarelarios" label="Relatorio"
                            name="sistema" item-text="nome" item-value="id" v-model="relatorio" outlined dense hide-details return-object >
                        </v-select>
                  </v-col>

                  <v-col></v-col>

                  <v-col>
                        <v-btn elevation="" color="error" @click="() =>limparFiltros()" block> Limpar </v-btn>                         
                  </v-col>

                  <v-col>
                        <v-btn elevation="" color="info" @click="realizarConsulta()" block> Consultar</v-btn>
                  </v-col>
                </v-row>

              </v-container>


            
            <div style="display:flex;padding-top:20px;justify-content:flex-end;">
                    
                
            </div>
        </div>

        <div style="display:flex;">
            <div style="width:100%;padding:20px;">
            
                <div style="">
                    <!-- <h1>Clientes Com Mais Utilização</h1> -->
                    <v-data-table v-if="headers  && itemsmais && headers.length >0 && itemsmais.length >0"
                        :headers="headers"
                        :items="itemsmais"
                        :items-per-page="10"
                        
                    >
                        <template v-slot:[`item.dia`]="{ item }">
                            {{item.dia | formatarDataDD }}
                        </template>

                        <template v-slot:[`item.dtultfichaimportada`]="{ item }">
                            {{item.dtultfichaimportada | formatarDataDD }}
                        </template>

                        <template v-slot:[`item.dtultfichafinalizada`]="{ item }">
                            {{item.dtultfichafinalizada | formatarDataDD }}
                        </template>

                        <template v-slot:[`item.porcentagemfinalizada`]="{ item }">
                           <span v-if="item.porcentagemfinalizada" :style="(item.porcentagemfinalizada>=50?'color:green;':'color:red;')+'font-weight:bold;'"> {{item.porcentagemfinalizada +"%" }}</span>
                        </template>

                        <template v-slot:[`item.porcentagemwebcheckin`]="{ item }">
                           <span v-if="item.porcentagemwebcheckin" :style="(item.porcentagemwebcheckin>=50?'color:green;':'color:red;')+'font-weight:bold;'"> {{item.porcentagemwebcheckin +"%" }}</span>
                        </template>
                        
                    </v-data-table>
                </div>
            </div>
            
        </div>

        

        
    </v-container>
</template>

<script>

import dash from '@/services/dashboard.service.js'
import ClienteServico from '@/services/cliente.service.js'
// import dayjs from 'dayjs'

export default {
  components: {

  },
  data(){
    return{
        sistema:{id:1,nome:'Ficha Digital'},
        listadesistemas:[
            {id:1,nome:'Ficha Digital'},
            {id:2,nome:'Cardápio Digital'},
        ],
        modo:1,
        listademodos:[
            {id:1,nome:'Geral'},
            {id:2,nome:'Sem Utilização'},
        ],
        cliente:{},
        listadecliente:[],
        relatorio: {id:1, nome:'Utilização Diária'},
        listarelarios:[
                    {id:0, nome:'Implantações'},
                    {id:1, nome:'Utilização Diária'},
                    {id:6, nome:'Utilização Média'},
                    {id:4, nome:'Hoteis com Importação de Fichas nos ultimos 3 dias'},
                    {id:5, nome:'Hoteis com Finalização de Fichas nos ultimos 3 dias'},
                    {id:2, nome:'Hoteis sem Importação de Fichas a mais de 3 dia'},
                    {id:3, nome:'Hoteis sem Finalização de Fichas a mais de 3 dia'}
                ],
        headers:null,
        items:null,
        itemsmenos:null,
        itemsmais:null,
        diainicial:null,
        diafinal:null,
    }
  },
  methods:{
        limparFiltros(){
            this.sistema = {},
            this.cliente = {},
            this.diainicial = null 
            this.diafinal = null 
            this.relatorio = {}
        },
        selecionarTipoRelatorio(){
            // alert(JSON.stringify(this.sistema,null,2))
            if(this.sistema.id == 1){
                this.relatorio = {id:0, nome:'Implantações'}
                this.listarelarios =[
                    {id:0, nome:'Implantações'},
                    {id:1, nome:'Utilização Diária'},
                    {id:6, nome:'Utilização Média'},
                    {id:4, nome:'Hoteis com Importação de Fichas nos ultimos 3 dias'},
                    {id:5, nome:'Hoteis com Finalização de Fichas nos ultimos 3 dias'},
                    {id:2, nome:'Hoteis sem Importação de Fichas a mais de 3 dia'},
                    {id:3, nome:'Hoteis sem Finalização de Fichas a mais de 3 dia'}
                ]
            }

            if(this.sistema.id == 2){
                this.relatorio = {id:1, nome:'Cardapio'}
                this.listarelarios =[
                    {id:1, nome:'Cardapio'},
                ]
            }

        },
        async requisitarClientes(){
            this.listadecliente = await ClienteServico.buscar().then(res => res.data)
        },
        async realizarConsulta(){
            
    
            //FICHA DIGITAL
            if(this.sistema.id == 1){

                    this.headers = [
                            { text: 'Cliente',    align: 'start', value: 'cliente', sortable: false },
                            { text: 'Dia Utilização',    align: 'start', value: 'dia', sortable: true },
                            { text: 'Importadas',  align: 'start', value: 'fichasimportadas',  sortable: true},
                            { text: 'CkIns do Dia',  align: 'start', value: 'qtde_checkins_dia', sortable: true },
                            { text: 'Finalizadas',  align: 'start', value: 'fichasfinalizadas', sortable: true },
                            { text: '%',  align: 'start', value: 'porcentagemfinalizada', sortable: true },
                            { text: 'WebCheckins',  align: 'start', value: 'qtde_web_checkins', sortable: true },
                            { text: '%',  align: 'start', value: 'porcentagemwebcheckin', sortable: true },
                            { text: 'Ult. Importação',  align: 'start', value: 'dtultfichaimportada', sortable: true },
                            { text: 'Ult. Finalizada',  align: 'start', value: 'dtultfichafinalizada', sortable: true },
                    ]

                    if(this.relatorio.id == 0){

                        this.headers = [
                                // { text: 'Ordem Fluxo de Implantação',    align: 'start', value: 'ordem', sortable: false },
                                { text: 'Quantidade',    align: 'start', value: 'qtde', sortable: true },
                                { text: 'Etapa Atual',    align: 'start', value: 'nomeetapa', sortable: true }
                        ]

                        this.diainicial = null
                        this.diafinal = null
                    }

                    if(this.relatorio.id == 2){
                        this.diainicial = null
                        this.diafinal = null
                    }

                    if(this.relatorio.id == 3){
                        this.diainicial = null
                        this.diafinal = null
                    }
                
            }

            //FICHA CARDAPIO DIGITAL
            if(this.sistema == 2){

                this.headers = [
                        { text: 'Cliente',    align: 'start', value: 'id', sortable: false },
                        { text: 'Qtde Comandas Entregues',  align: 'start', value: 'nome',  sortable: false},
                        { text: 'Dt Ult. Comanda Entregue',  align: 'start', value: 'ativo', sortable: false },
                        { text: 'Dt Ult. Hospede Importado',  align: 'start', value: 'ativo', sortable: false },
                ]

            }

            //REALIZAR CONSULTA
            let dados = {
                    idsistema:this.sistema.id,
                    cliente:this.cliente.id,
                    diainicial:this.diainicial,
                    diafinal:this.diafinal,
                    tiporelatorio:this.relatorio.id
                    // idmodo:this.modo
                }
            this.itemsmais  = await dash.consultarDadosDashMais(dados).then(res=> res.data)


        }
  },
  async mounted(){
      await this.requisitarClientes()
      await this.selecionarTipoRelatorio()
    //   this.diainicial = dayjs().format('YYYY-MM-DD')
    //   this.diafinal = dayjs().format('YYYY-MM-DD')
      await this.realizarConsulta()

      
  }
}
</script>

<style>

</style>