import api from './api'

export default {
  consultarDadosDash(body){

    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    console.log(JSON.stringify(config,null,2))
    return api().post('/dashboard/consultarDadosDash', body, config)
  },
  consultarDadosDashMenos(body){

    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    console.log(JSON.stringify(config,null,2))
    return api().post('/dashboard/consultarDadosDashMenos', body, config)
  },
  consultarDadosDashMais(body){

    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    console.log(JSON.stringify(config,null,2))
    return api().post('/dashboard/consultarDadosDashMais', body, config)
  },
  consultarClientesPorStatus(body){

    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    console.log(JSON.stringify(config,null,2))
    return api().post('/dashboard/consultarClientesPorStatus', body, config)
  },
  consultarClientesPorEtapa(body){

    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    //console.log(JSON.stringify(config,null,2))
    return api().post('/dashboard/consultarClientesPorEtapa', body, config)
  },
  consultaClientesMaiorUso(body){

    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    
    return api().post('/dashboard/consultaClientesMaiorUso', body, config)
  },
  consultaClientesMenorUso(body){

    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    console.log(JSON.stringify(config,null,2))
    return api().post('/dashboard/consultaClientesMenorUso', body, config)
  }
}